import { useId } from "react";
import { getRatingInStar } from "@utils/getRating";
import RatingStar from "@public/svg/icons/rating_star.svg";
import RatingHalfStar from "@public/svg/icons/rating_star_half.svg";
import RatingFullStar from "@public/svg/icons/rating_full_star.svg";
import styles from "./RatingStars.module.scss";

type Props = { rating: number };

export default function RatingStars({ rating }: Props) {
  const starsType = getRatingInStar(rating);
  const id = useId();

  return (
    <div className={styles.rating_stars}>
      {starsType.map(elem => {
        const key = `${id}-${new Date().getTime() * Math.random() + 1}`;

        switch (elem) {
          case "full":
            return <RatingFullStar key={key} />;
          case "empty":
            return <RatingStar key={key} />;
          case true:
            return <RatingHalfStar key={key} />;
          default:
            return null;
        }
      })}
    </div>
  );
}
