import { ExchangeRates } from "@common/type/type";

export const isExchangerHighlight = (exchanger: ExchangeRates) => {
  if (!exchanger.minamount || !exchanger.maxamount) return true;

  const isMoreThanMin = +exchanger.fromAmount >= exchanger.minamount;
  const isLessThanMax = +exchanger.fromAmount <= exchanger.maxamount;

  return isMoreThanMin && isLessThanMax;
};
