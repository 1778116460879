import MIcon from "@public/svg/icons/M.svg";
import HowWork from "@public/svg/icons/type_of_work.svg";
import KeyIcon from "@public/svg/icons/key.svg";
import PercentIcon from "@public/svg/icons/percent.svg";
import ChangeIcon from "@public/svg/icons/change.svg";

export const ExchangersIconsValues = [
  "time_of_work",
  "verify_documents",
  "mondatory_regitstration",
  "commission_included",
  "fixed_rate",
] as const;

export const ExchangersIconsData = [
  {
    icon: MIcon,
    text: "time_of_work",
  },
  {
    icon: HowWork,
    text: "verify_documents",
  },
  {
    icon: KeyIcon,
    text: "mondatory_regitstration",
  },
  {
    icon: PercentIcon,
    text: "commission_included",
  },
  {
    icon: ChangeIcon,
    text: "fixed_rate",
  },
] as const;
