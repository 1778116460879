import { useQuery } from "react-query";
import { getCountries } from "@api/get-countries.api";
import { AppLanguage } from "@common/enum/AppLanguage.enum";

export const useCountries = (locale: string) => {
  const lang = locale as AppLanguage;

  return useQuery({
    queryKey: ["countries", lang],
    queryFn: async () => {
      const countries = await getCountries(lang);

      return countries.sort((countryA, countryB) =>
        countryA.name.localeCompare(countryB.name)
      );
    },
    staleTime: Infinity,
  });
};
