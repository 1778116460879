import { ChangeEvent, useState } from "react";
import { useTranslation } from "next-i18next";
import Typography from "@components/common/Typography";
import { CryptoType, Nullable } from "@common/type/type";
import { formatNumber } from "@utils/formatNum";
import CoverLoader from "@components/ui/Loader/CoverLoader";
import CurrencySelect from "../CurrencySelect";
import styles from "./CurreccyInput.module.scss";

type Props = {
  isEmpty?: boolean;
  target: CryptoType;
  value: Nullable<string>;
  onSelect: () => void;
  onInput: (newValue: string) => void;
  isLoading?: boolean;
  id: string;
};

export default function CurrencyInput({
  id,
  isEmpty,
  target,
  value,
  onSelect,
  onInput,
  isLoading,
}: Props) {
  const [inFocus, setInFocus] = useState(false);
  const { t } = useTranslation("common");

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const isValid = e.target.validity.valid;

    if (!isValid) return;

    const nextValue = e.target.value;

    if (nextValue.match(/^0+\./)) {
      onInput(nextValue.replace(/^0+\./, "0."));
      return;
    }

    if (nextValue.match(/^0+/)) {
      onInput(String(parseFloat(nextValue)));
      return;
    }

    onInput(nextValue);
  };

  return (
    <div className={styles.wrapper}>
      {!isEmpty && (
        <>
          <CurrencySelect target={target} onSelect={onSelect} />
          {target.id && typeof value === "string" ? (
            <input
              onFocus={() => setInFocus(true)}
              onBlur={() => setInFocus(false)}
              id={id}
              autoComplete="off"
              pattern="^\d+\.?\d*?$"
              className={styles.currency_input}
              onChange={handleInput}
              value={inFocus ? value : formatNumber(value)}
            />
          ) : (
            <Typography addClass={styles.placeholder} as="span">
              {t("currency-placeholder")}
            </Typography>
          )}
        </>
      )}
      {isLoading && <CoverLoader />}
    </div>
  );
}
