import { useTranslation } from "react-i18next";
import TooltipItem from "@components/common/TooltipItem";
import Typography from "@components/common/Typography";
import { ExchangeRates } from "@common/type/ExchangerData.type";
import {
  ExchangersIconsData,
  ExchangersIconsValues,
} from "@data/exchangersIconsData";
import styles from "./ExchangeFeatures.module.scss";

type Props = {
  exchanger?: ExchangeRates;
  onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

const getIcons = (exchanger?: ExchangeRates) => {
  if (!exchanger) return [];

  return ExchangersIconsValues.filter(item => Boolean(exchanger[item]));
};

export default function ExchangerFeatures({ exchanger, onClick }: Props) {
  const { t } = useTranslation(["tooltip"]);

  return (
    <a
      target="_blank"
      href={exchanger?.website_url || "#"}
      rel="noreferrer"
      className={styles.icons}
      onClick={onClick}
    >
      {getIcons(exchanger).map((item, idx) => {
        const Icon = ExchangersIconsData[idx].icon;

        const title = (
          <Typography as="p" addClass={styles.icon_text}>
            {t(`tooltip:${ExchangersIconsData[idx].text}`)}
          </Typography>
        );

        return (
          <TooltipItem key={item} content={title}>
            <Icon />
          </TooltipItem>
        );
      })}
    </a>
  );
}
