import { Tooltip } from "@nextui-org/react";
import { TooltipPlacement } from "@common/enum/enum";
import styles from "./TooltipItem.module.scss";

interface TooltipItemProps {
  children?: React.ReactNode;
  content?: React.ReactNode;
  placement?: TooltipPlacement;
}

export default function TooltipItem({
  children,
  content,
  placement = TooltipPlacement.BOTTOM,
}: TooltipItemProps) {
  return (
    <Tooltip
      hideArrow
      placement={placement}
      color="primary"
      portalClassName={styles.tooltip__item}
      css={{ $$tooltipColor: "rgba(116, 116, 116, 0.31)", $$tooltipPadding: 0 }}
      content={content}
    >
      {children}
    </Tooltip>
  );
}
