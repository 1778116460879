import { useEffect } from "react";

export const useScrollLock = (withHeader?: boolean) => {
  useEffect(() => {
    let scrollbarWidth: number;
    if (window !== undefined) {
      scrollbarWidth = window.innerWidth - document.body.clientWidth;
    } else {
      scrollbarWidth = 0;
    }
    const html = document.getElementsByTagName("html")[0];

    Object.assign(html.style, {
      marginRight: `${scrollbarWidth}px`,
    });

    html?.classList.add("scroll-fixed");

    const header = document.querySelector("header");

    if (withHeader) {
      header?.setAttribute("style", "pointer-events: none");
    }
    return () => {
      html?.classList.remove("scroll-fixed");
      Object.assign(html.style, {
        marginRight: "unset",
      });

      if (withHeader) {
        header?.removeAttribute("style");
      }
    };
  }, []);
};
