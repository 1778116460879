import Select, { ActionMeta, SingleValue, StylesConfig } from "react-select";
import { Country, OptionsDataProps } from "@common/type/type";
import { AppColor } from "@common/enum/enum";
import useMediaQuery from "app/hooks/useMediaQuery";
import CustomIndicatorsContainer from "./CustomIndicatorsContainer";
import CustomOption from "./CustomOption";
import style from "./SelectSIngleLocation.module.scss";

type Props = {
  isLoading?: boolean;
  options: Array<OptionsDataProps & { country?: Country }>;
  placeholder: string;
  noOptionsMessage?: string;
  selectedId?: string;
  onChange?: (
    newValue: SingleValue<OptionsDataProps>,
    actionMeta: ActionMeta<OptionsDataProps>
  ) => void;
  disabled?: boolean;
};

const getCustomStyles = (
  isMobile: boolean
): StylesConfig<OptionsDataProps, false> => {
  return {
    valueContainer: provided => ({
      ...provided,
      padding: 0,
    }),
    control: (base, props) => {
      return {
        display: "flex",
        alignItems: "center",
        background: AppColor.COLOR_DARK,
        borderRadius: "8px",
        border: `1px solid ${AppColor.DROPDOWN_BORDER}`,
        padding: 16,
        pointerEvents: "auto",
        cursor: props.isDisabled ? "not-allowed !important" : "default",
      };
    },
    menu: provided =>
      isMobile
        ? {
            ...provided,
            background: "#353535",
            overflow: "hidden",
            top: "initail",
            bottom: "100%",
            maxHeight: 180,
          }
        : {
            ...provided,
            background: "#353535",
            top: "calc(100% + 12px)",
            right: 0,
            margin: 0,
            overflow: "hidden",
            borderRadius: 8,
          },
    menuList: provided => ({
      ...provided,
      "::-webkit-scrollbar": { width: 0 },
      padding: 0,
      maxHeight: isMobile ? 180 : 256,
      height: !isMobile ? "100%" : "auto",
    }),
    option: (provided, { isSelected }) => ({
      ...provided,
      padding: isMobile ? "8px" : "16px",
      fontSize: isMobile ? "14px" : provided.fontSize,
      flexDirection: "row",
      alignItems: "center",
      cursor: "pointer",
      color: isSelected ? AppColor.NEW_PRIMARY : AppColor.WHITE,
      backgroundColor: isSelected
        ? AppColor.OPTION_BG_SELECTED
        : AppColor.LIST_BORDER,
      "&:hover": {
        backgroundColor: !isSelected ? AppColor.OPTION_COLOR_FOCUSED : "",
      },
    }),
    indicatorsContainer: provided => ({
      ...provided,
      transform: "rotate(90deg)",
    }),
    singleValue: provided => ({
      ...provided,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      color: AppColor.WHITE,
    }),
    placeholder: provided => ({
      ...provided,
      fontSize: 16,
      fontWeight: 400,
    }),
    input: provided => ({
      ...provided,
      color: AppColor.WHITE,
    }),
    noOptionsMessage: provided => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }),
  };
};

export default function SelectSingleLocation({
  isLoading,
  options,
  placeholder,
  onChange,
  selectedId,
  noOptionsMessage = "No result",
  disabled,
}: Props) {
  const isMobile = useMediaQuery("(max-width: 768px)");

  if (isLoading && !isMobile) {
    return <div />;
  }

  return (
    <div className={style.select_container}>
      <Select
        isSearchable
        styles={getCustomStyles(Boolean(isMobile))}
        placeholder={placeholder}
        options={options}
        onChange={onChange}
        isDisabled={disabled || isLoading}
        noOptionsMessage={() => <div>{noOptionsMessage}</div>}
        components={{
          IndicatorSeparator: () => null,
          IndicatorsContainer: props => (
            <CustomIndicatorsContainer props={props} extra={{ isLoading }} />
          ),
          Option: props => {
            const { data } = props;
            const { id: targetId } = data;
            const isSelected = targetId === selectedId;
            const country = options.find(({ id }) => id === targetId)?.country;

            const updatedProps = isSelected
              ? { ...props, isSelected: true }
              : props;

            return <CustomOption props={updatedProps} country={country} />;
          },
        }}
      />
    </div>
  );
}
