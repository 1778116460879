import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { getCurrencyRates } from "@api/get-exchange-rates.api";
import { ExchangeRatesResponse, GlobalContextState } from "@common/type/type";
import { DEFAULT_RATE_RESPONSE } from "@common/constant/DefualtRateResponse.constant";
import { off } from "process";

export const useCurrencyRates = (
  state: GlobalContextState,
  options?: UseQueryOptions<ExchangeRatesResponse>
) => {
  const { currency_pairs, amount, currentCity, currencyRatesTable } = state;
  const { filter, order, limit, offset } = currencyRatesTable;
  const [first, second] = currency_pairs;
  const requestBody = {
    currency_from: first.abbreviation,
    currency_to: second.abbreviation,
    amount,
    city: currentCity?.short_name,
  };

  const queryClient = useQueryClient();
  const initQueryKeys = [
    "currency-list",
    first.abbreviation,
    second.abbreviation,
    amount,
    currentCity?.short_name,
    order,
    filter,
  ];

  const fetchTotalData = async (limit: number) => {
    if (currency_pairs.some(({ id }) => !id)) return DEFAULT_RATE_RESPONSE;

    return getCurrencyRates(requestBody, filter, order, limit, offset);
  };

  const queryOptions: UseQueryOptions<ExchangeRatesResponse> = {
    queryKey: [...initQueryKeys, limit, offset],
    queryFn: async () => {
      const data = queryClient.getQueryData([
        ...initQueryKeys,
        "all",
      ]) as ExchangeRatesResponse | null;

      if (data) {
        const chunks = data.items.slice(offset, offset + limit);

        return { items: chunks, limit, offset, total: data.total };
      }

      const totalData = await fetchTotalData(limit);

      if (limit + offset < totalData.total) {
        queryClient.prefetchQuery({
          queryKey: [...initQueryKeys, "all"],
          queryFn: () => fetchTotalData(totalData.total),
          cacheTime: 5 * 60 * 1000,
        });
      }

      return totalData;
    },
    onSuccess: options?.onSuccess,
    refetchInterval: 5 * 60 * 1000,
    retry: 0, // Limit the number of retries to 1
    onError: error => {
      console.error("Error fetching currency rates:", error);
    },
  };

  if (options) {
    queryOptions.keepPreviousData = options.keepPreviousData;
  }

  return useQuery(queryOptions);
};

export const useCurrencyRatesInfinite = (
  state: GlobalContextState,
  options?: UseInfiniteQueryOptions<ExchangeRatesResponse>
) => {
  const { currency_pairs, amount, currentCity, currencyRatesTable } = state;
  const { filter, order } = currencyRatesTable;

  const [first, second] = currency_pairs;
  const requestBody = {
    currency_from: first.abbreviation,
    currency_to: second.abbreviation,
    amount,
    city: currentCity?.short_name,
  };

  const fetchCurrencyRates = async ({ pageParam = 0 }) => {
    return getCurrencyRates(requestBody, filter, order, 5, pageParam);
  };

  const queryOptions: UseInfiniteQueryOptions<ExchangeRatesResponse> = {
    queryKey: [
      "currency-rates",
      {
        currency_from: first.abbreviation,
        currency_to: second.abbreviation,
        amount,
        city: currentCity?.short_name,
        filter,
        order,
      },
    ],
    queryFn: fetchCurrencyRates,
    onSuccess: data => {
      if (options?.onSuccess) {
        options.onSuccess(data);
      }
    },
    refetchInterval: 5 * 60 * 1000,
    retry: 0,
    keepPreviousData: options?.keepPreviousData,
  };

  return useInfiniteQuery(queryOptions);
};
