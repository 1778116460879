import { useForm } from "react-hook-form";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { toast } from "react-toastify";
import { joiResolver } from "@hookform/resolvers/joi";
import { AddExchangerValidationSchema } from "app/validation-schema";
import { FeedbackFormData } from "@common/type/Form.type";
import Typography from "@components/common/Typography";
import { CancelButton, ContainedButton } from "@components/common/Button";
import { useSendFeedback } from "app/hooks/use-feedback";
import { Checkbox, Input, Textarea } from "../components";
import styles from "./AddExchangerForm.module.scss";

type Props = {
  onSubmit: () => void;
};

export default function AddExchangerForm({ onSubmit }: Props) {
  const { t } = useTranslation("form");
  const {
    register,
    handleSubmit: submitCallback,
    formState,
    reset,
  } = useForm<FeedbackFormData>({
    resolver: joiResolver(AddExchangerValidationSchema),
  });
  const { errors } = formState;

  const handleError = () => toast.error(t("form-send-error"));
  const handleSuccess = () => {
    toast.success(t("form-send-success"));
    reset();
    onSubmit();
  };

  const { mutate } = useSendFeedback(handleError, handleSuccess);

  const handleSubmit = (data: FeedbackFormData) => mutate(data);

  return (
    <form onSubmit={submitCallback(handleSubmit)} className={styles.wrapper}>
      <Typography as="h3" addClass={styles.title}>
        {t("title")}
      </Typography>
      <Input
        register={register}
        name="name"
        placeholder={t("name") || ""}
        errorMessage={errors.name?.message}
        autoComplete="off"
      />
      <Input
        register={register}
        name="email"
        placeholder={t("email") || ""}
        errorMessage={errors.email?.message}
        autoComplete="off"
      />
      <Textarea
        register={register}
        name="comment"
        placeholder={t("message") || ""}
        errorMessage={errors.comment?.message}
        autoComplete="off"
      />
      <Checkbox
        register={register}
        name="terms"
        errorMessage={errors.terms?.message}
      >
        <Typography addClass={styles.checkbox_title} as="p">
          {t("read")}{" "}
          <Link href="/terms" className={styles.checkbox_link}>
            {t("term_to_use")}
          </Link>
        </Typography>
      </Checkbox>
      <Checkbox
        register={register}
        name="policy"
        errorMessage={errors.policy?.message}
      >
        <Typography addClass={styles.checkbox_title} as="p">
          {t("agree")}{" "}
          <Link href="/policy" className={styles.checkbox_link}>
            {t("privacy_policy")}
          </Link>
        </Typography>
      </Checkbox>
      <div className={styles.submit_container}>
        <ContainedButton
          type="submit"
          className={styles.btn}
          title={t("btn")}
        />
      </div>
      <div className={styles.cancel_container}>
        <CancelButton title={t("cancel")} onClick={onSubmit} />
      </div>
    </form>
  );
}
