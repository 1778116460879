import styles from "./CoverLoader.module.scss";

export default function CoverLoader() {
  const { cover_layer, react_loading_skeleton } = styles;

  return (
    <div className={cover_layer}>
      <div className={react_loading_skeleton} />
    </div>
  );
}
