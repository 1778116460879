import { useContext } from "react";
import { useTranslation } from "next-i18next";
import { GlobalContext } from "@context/GlobalContext";
import Typography from "@components/common/Typography";
import { formatNumber } from "@utils/formatNum";
import { ExchangeRates } from "@common/type/type";
import styles from "./ExchangeReserve.module.scss";

type Props = {
  reserve: string;
  exchanger?: ExchangeRates;
  onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

export default function ExchangeReserve({
  reserve,
  exchanger,
  onClick,
}: Props) {
  const { state } = useContext(GlobalContext);
  const [, target] = state.currency_pairs;
  const { abbreviation } = target;

  const { t } = useTranslation("common, monitoring");

  return (
    <a
      target="_blank"
      href={exchanger?.website_url || "#"}
      rel="noreferrer"
      onClick={onClick}
      className={styles.container}
    >
      <Typography as="p">
        <span className={styles.title}>{t("monitoring:reserve")}</span>
        {`${formatNumber(reserve)} ${abbreviation.toUpperCase()}` ||
          t("noInfo")}
      </Typography>
    </a>
  );
}
