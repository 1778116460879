import { PropsWithChildren, useRef } from "react";
import { SwipeEventData, useSwipeable } from "react-swipeable";
import CloseIcon from "@public/svg/icons/close.svg";
import {
  SWIPE_BREAKPOINT,
  SWIPE_ANIMATION_TIME,
} from "@common/constant/Modal.constant";
import { useScrollLock } from "app/hooks/use-scroll-lock";
import styles from "./Modal.module.scss";

type Props = {
  onClose: () => void;
} & PropsWithChildren;

export default function Modal({ children, onClose }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useScrollLock(true);

  const handleSwiping = ({ deltaY }: SwipeEventData) => {
    if (ref.current && deltaY > 0) {
      ref.current.style.bottom = `${-deltaY}px`;
    }
  };

  const handleSwipeEnd = ({ deltaY: stopPoint }: SwipeEventData) => {
    const { current } = ref;

    if (!current) return;

    const mustClose = stopPoint > SWIPE_BREAKPOINT;
    const endPoint = mustClose ? "-100%" : "0";
    const bezierFunction = mustClose ? "ease-in" : "ease-out";

    current.style.bottom = endPoint;
    current.style.transition = `bottom ${bezierFunction} ${SWIPE_ANIMATION_TIME}ms`;

    setTimeout(() => {
      current.style.transition = "";

      if (mustClose) onClose();
    }, SWIPE_ANIMATION_TIME);
  };

  const handlers = useSwipeable({
    onSwiped: handleSwipeEnd,
    onSwiping: handleSwiping,
  });

  const handlePropagation = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
  };

  return (
    <div
      role="button"
      aria-hidden
      ref={wrapperRef}
      className={styles.modal_wrapper}
      onClick={onClose}
    >
      <div
        role="button"
        aria-hidden
        ref={ref}
        className={styles.modal_container}
        onClick={handlePropagation}
      >
        <div aria-hidden className={styles.exit_container} onClick={onClose}>
          <CloseIcon />
        </div>
        <div {...handlers} className={styles.slide_wrapper}>
          <div className={styles.slide_container} />
        </div>
        {children}
      </div>
    </div>
  );
}
