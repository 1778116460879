import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@components/common/Typography";
import { ContainedButton } from "@components/common/Button";
import AddExchangerForm from "@components/ui/Form/AddExchangerForm";
import Modal from "@components/ui/Modal";
import LocationSearchForm from "@components/common/LocationSearchForm";
import { CurrencyCategory } from "@common/enum/CurrencyCategory.enum";
import { GlobalContext } from "@context/GlobalContext";
import styles from "./ExchangeNoFound.module.scss";

export default function ExchangeNoFound() {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation("home");
  const [isCountrySelectOpen, setIsCountrySelectOpen] = useState(false);
  const { state } = useContext(GlobalContext);
  const { currency_pairs } = state;
  const hasCash = currency_pairs.some(
    ({ category }) => category === CurrencyCategory.CASH
  );

  return (
    <>
      <div className={hasCash ? styles.container_cash : styles.container}>
        <Typography addClass={styles.title} as="h2">
          {t("no-found-title")}
        </Typography>

        <div className={styles.buttons_group}>
          <ContainedButton
            className={styles.button}
            onClick={() => setIsOpen(true)}
            title={t("create-exchanger-review")}
          />

          {hasCash && (
            <ContainedButton
              className={styles.button}
              onClick={() => setIsCountrySelectOpen(true)}
              title={t("choose-another-city")}
            />
          )}
        </div>
      </div>
      {isOpen && (
        <Modal onClose={() => setIsOpen(false)}>
          <AddExchangerForm onSubmit={() => setIsOpen(false)} />
        </Modal>
      )}

      {isCountrySelectOpen && (
        <LocationSearchForm onClose={() => setIsCountrySelectOpen(false)} />
      )}
    </>
  );
}
