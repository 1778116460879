import { useState } from "react";
import urlRegex from "url-regex";
import QuestionImg from "@public/svg/icons/question_mark.svg";
import styles from "./ExchangerIcon.module.scss";

type Props = {
  src: string;
  alt: string;
  width?: number;
  height?: number;
};

export default function ExchangerIcon({
  src,
  alt,
  width = 24,
  height = width,
}: Props) {
  const isValidUrl = urlRegex().test(src);
  const [isError, setIsError] = useState(false);

  return (
    <div className={styles.icon_container}>
      {isValidUrl && !isError ? (
        <img
          className={styles.icon}
          width={width}
          height={height}
          src={src}
          alt={alt}
          onError={() => setIsError}
        />
      ) : (
        <QuestionImg />
      )}
    </div>
  );
}
