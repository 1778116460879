import { OptionProps, components } from "react-select";
import { Country, OptionsDataProps } from "@common/type/type";
import styles from "./CustomOption.module.scss";

const { Option } = components;

type Props = {
  props: OptionProps<OptionsDataProps>;
  country?: Country;
};

export default function CustomOption({ props, country }: Props) {
  return (
    <Option {...props}>
      {props.data.label}
      {country && <span className={styles.country_tag}>{country.name}</span>}
    </Option>
  );
}
