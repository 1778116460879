import classNames from "classnames";
import { CurrencyIcon, DropdownArrowIcon } from "@components/common/Icon";
import { CryptoType } from "@common/type/type";
import styles from "./CurrencySelect.module.scss";

type Props = {
  target: CryptoType;
  onSelect: () => void;
};

export default function CurrencySelect({ onSelect, target }: Props) {
  return (
    <div
      aria-hidden="true"
      onClick={onSelect}
      className={styles.select_container}
    >
      <div
        className={classNames(
          styles.icon_container,
          !target.image && styles.placeholder
        )}
      >
        {target.id && <CurrencyIcon src={target.image} alt={target.name} />}
      </div>
      <div className={styles.dropdown_container}>
        <DropdownArrowIcon fill="white" />
      </div>
    </div>
  );
}
