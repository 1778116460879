import SortButton from "@components/common/SortButton/index.";
import Typography from "@components/common/Typography";
import styles from "./ExchangeSortButton.module.scss";

type Props = {
  handleSort: () => void;
  title: string;
  isAsc: boolean;
  isActive?: boolean;
  arrowIsVisible?: boolean;
};

export default function ExchangeSortButton({
  handleSort,
  title,
  isAsc,
  isActive,
  arrowIsVisible = true,
}: Props) {
  const { sort_button } = styles;

  return (
    <div className={sort_button} onClick={handleSort} aria-hidden="true">
      <Typography as="h5" addClass={styles.title}>
        {title}
      </Typography>
      {arrowIsVisible ? <SortButton sort={isAsc} isActive={isActive} /> : <></>}
    </div>
  );
}
