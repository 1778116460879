import { useTranslation } from "next-i18next";
import { Roboto } from "next/font/google";
import LoadButton from "@public//svg/icons/load_button.svg";
import ArrowIcon from "@public/svg/icons/arrow_downward.svg";
import styles from "./ExpandButton.module.scss";

const roboto = Roboto({
  weight: ["400", "500", "700"],
  style: ["normal"],
  subsets: ["latin"],
});

type Props = {
  isFetching: boolean;
  handleClick: () => void;
};

export default function ExpandButton({ isFetching, handleClick }: Props) {
  const { t } = useTranslation(["common"]);

  const btnIcon = isFetching ? (
    <LoadButton className={styles.spinner} />
  ) : (
    <ArrowIcon />
  );

  return (
    <button
      type="button"
      disabled={isFetching}
      className={styles.expand_button}
      onClick={handleClick}
    >
      {btnIcon}
      <span className={roboto.className}>{t("expand")}</span>
    </button>
  );
}
