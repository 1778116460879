import { AppLanguage } from "@common/enum/enum";
import { Country } from "@common/type/type";
import { APIService } from "app/service/api-service";

export const getCountries = async (lang: AppLanguage) => {
  const params = new URLSearchParams({ lang });
  const url = "/country/get/all";

  return APIService.get<Array<Country>>(`${url}?${params}`);
};
