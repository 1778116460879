import React, { useRef, useState } from "react";
import { useTranslation } from "next-i18next";
import classNames from "classnames";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import styles from "./SortingMobile.module.scss";
import { OrderIcon } from "../Icon";

type SortingValue = {
  key: string;
  title: string;
};

type Props = {
  disabled?: true;
  disabledClick?: boolean;
  options: Array<SortingValue>;
  order: boolean;
  filter: string;
  onSort: (filter: string) => void;
  onOrder: () => void;
  right?: true;
};

export default function SortingMobile({
  disabled,
  options,
  order,
  filter,
  onSort,
  onOrder,
  right,
  disabledClick,
}: Props) {
  const { t } = useTranslation(["home"]);
  const sortingRef = useRef<HTMLDivElement>(null);
  const [opened, setOpened] = useState(false);
  const currentSort = options.find(({ key }) => key === filter);

  useOutsideClick({ elementRef: sortingRef, setOpened });

  return (
    <div
      className={classNames(
        styles.sorting,
        disabledClick && styles.disabled,
        opened && styles.opened
      )}
    >
      <button
        onClick={() => {
          if (!disabled) onOrder();
        }}
        aria-label="sort order"
        type="button"
        className={classNames(styles.sort_icon, {
          [styles.sort_icon_asc]: !order,
        })}
      >
        <OrderIcon />
      </button>
      <div className={styles.dropdown} ref={sortingRef}>
        <button
          onClick={() => {
            if (!disabled) setOpened(!opened);
          }}
          type="button"
          className={styles.dropdown__button}
        >
          {t(currentSort?.title || "sort-by-rating")}
        </button>
      </div>
      <ul
        id="myDropdown"
        className={classNames(
          styles.dropdown__content,
          !right && styles.right,
          opened && styles.dropdown__content_show
        )}
      >
        {options.map(({ key, title }) => (
          <button
            type="button"
            key={key}
            id={key}
            className={classNames(
              styles.dropdown__text,
              key === filter && styles.dropdown__text_selected
            )}
            onClick={() => onSort(key)}
          >
            {t(`${title}`)}
          </button>
        ))}
      </ul>
    </div>
  );
}
