import React, { Dispatch, useEffect } from "react";

interface IUseOutsideClickOptions {
  elementRef: React.RefObject<HTMLElement>;
  setOpened: Dispatch<React.SetStateAction<boolean>>;
}

export const useOutsideClick = ({
  elementRef,
  setOpened,
}: IUseOutsideClickOptions) => {
  useEffect(() => {
    const handleScroll = () => {
      setOpened(false);
    };
    const handleClick = (e: MouseEvent) => {
      const { target } = e;
      if (!(target instanceof Node)) {
        return;
      }

      if (!elementRef.current) {
        return;
      }

      if (!elementRef.current.contains(target)) {
        setOpened(false);
      }
    };

    document.addEventListener("click", handleClick);
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
};
