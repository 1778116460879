import { AppLanguage } from "@common/enum/AppLanguage.enum";
import { City } from "@common/type/type";
import { APIService } from "app/service/api-service";

export const getCities = async (lang: AppLanguage, countryId?: string) => {
  const params = new URLSearchParams({ lang });
  const contryQuery = countryId || "all";
  const url = `/city/get/${contryQuery}?${params}`;

  return APIService.get<City[]>(url);
};
