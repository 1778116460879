import { useQuery, useQueryClient } from "react-query";
import { AppLanguage } from "@common/enum/AppLanguage.enum";
import { getCities } from "@api/get-cities.api";
import { Country } from "@common/type/type";

type CountryQueryState = {
  data: Array<Country>;
};

export const useCities = (locale: string, countryId?: string) => {
  const queryClient = useQueryClient();

  const lang = locale as AppLanguage;

  return useQuery({
    queryKey: ["cities", lang, countryId],
    queryFn: async () => {
      const cities = await getCities(lang, countryId);
      const { data } = queryClient.getQueryState([
        "countries",
        lang,
      ]) as CountryQueryState;

      const sortedCities = cities.sort((cityA, cityB) =>
        cityA.short_name.localeCompare(cityB.short_name)
      );

      return sortedCities.map(({ country_id, full_name, short_name, id }) => {
        const country = data.find(({ id }) => id === country_id);

        return { id, full_name, short_name, country };
      });
    },
    staleTime: Infinity,
  });
};
