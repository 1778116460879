import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import { GlobalContext } from "@context/GlobalContext";
import Modal from "@components/ui/Modal";
import { useCountries } from "app/hooks/use-countries";
import { useRouter } from "next/router";
import { AppLanguage } from "@common/enum/AppLanguage.enum";
import { useCities } from "app/hooks/use-cities";
import {
  CityWithCountry,
  GlobalContextKind,
  OptionsDataProps,
} from "@common/type/type";
import Typography from "../Typography";
import { CancelButton, ContainedButton } from "../Button";
import SelectSingleLocation from "./SelectSingleLocation";
import styles from "./Dropdown.module.scss";

type Props = {
  onClose: () => void;
};

export default function LocationSearchForm({ onClose }: Props) {
  const { state, dispatch } = useContext(GlobalContext);
  const { currentCity } = state;
  const [cityId, setCityId] = useState(currentCity?.id);
  const [countryId, setCountryId] = useState(currentCity?.country?.id);
  const router = useRouter();
  const { t } = useTranslation(["home", "form"]);

  const lang = (router.locale || router.defaultLocale) as AppLanguage;

  const { data: countries } = useCountries(lang);
  const { data: cities } = useCities(lang, countryId);

  const handleCountry = (value: SingleValue<OptionsDataProps>) => {
    setCountryId(value?.id);
  };

  const handleCity = (value: SingleValue<OptionsDataProps>) => {
    setCityId(value?.id);
  };

  const handleAccept = () => {
    if (!cities) return;

    const city = cities.find(({ id }) => id === cityId);

    if (!city) return;

    const cityWithCountry: CityWithCountry = {
      ...city,
      country: countries?.find(({ id }) => id === countryId),
    };

    dispatch({
      type: GlobalContextKind.CURRENT_CITY,
      payload: cityWithCountry,
    });
    onClose();
  };

  const handleCancel = () => onClose();

  return (
    <Modal onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.heading}>
          <Typography as="h3" addClass={styles.title}>
            {t("choose-location")}
          </Typography>
          <Typography as="p" addClass={styles.description}>
            {t("choose-location-description")}
          </Typography>
        </div>
        <div className={styles.countries_container}>
          <SelectSingleLocation
            selectedId={countryId}
            options={(countries || []).map(({ id, name }) => ({
              id,
              label: name,
              value: id,
            }))}
            placeholder={t("placeholder-country")}
            onChange={handleCountry}
          />
        </div>
        <div className={styles.cities_container}>
          <SelectSingleLocation
            selectedId={cityId}
            options={(cities || []).map(({ id, full_name, short_name }) => ({
              id,
              label: full_name || short_name,
              value: id,
            }))}
            placeholder={t("placeholder-city")}
            onChange={handleCity}
            disabled={!countryId}
          />
        </div>
        <div className={styles.accept_container}>
          <ContainedButton onClick={handleAccept} title={t("form:confirm")} />
        </div>
        <div className={styles.cancel_container}>
          <CancelButton onClick={handleCancel} title={t("form:cancel")} />
        </div>
      </div>
    </Modal>
  );
}
