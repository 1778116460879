import { useMutation } from "react-query";
import { createConsent } from "@api/create-consent.api";
import { sendFeedback } from "@api/send-feedback.api";
import { FeedbackFormData } from "@common/type/Form.type";
import { ConsentType } from "@common/type/type";
import { getIp } from "@api/getIp.api";

export const useSendFeedback = (
  onError?: () => void,
  onSuccess?: () => void
) => {
  const sendReview = async (data: FeedbackFormData) => {
    const { name, email, comment, policy, terms } = data;

    const ip = await getIp();

    const consentData: ConsentType = {
      ip,
      email,
      privacy_policy: policy,
      terms_of_website: terms,
    };

    await createConsent(consentData);

    return sendFeedback({
      user_name: name,
      user_email: email,
      comment,
    });
  };

  return useMutation(sendReview, { onError, onSuccess });
};
