import React, { useContext } from "react";
import classNames from "classnames";
import { GlobalContext } from "@context/GlobalContext";
import { isExchangerHighlight } from "@utils/isExchangerHighlight";
import { createPairClick } from "@api/create-pair.api";
import { createExchengerClick } from "@api/create-exchenger.api";
import { ExchangeRates } from "@common/type/type";
import ExchangerFeatures from "./ExchangeFeatures";
import ExchangerItemTitle from "./ExchangeItemTitle";
import ExchangeCourse from "./ExchangeCourse";
import ExchangeReserve from "./ExchangeReserve";
import ExchangeRating from "./ExchangeRating";
import ExchangerReviews from "./ExchangeReviews";
import styles from "./ExchangeTableItem.module.scss";

type Props = {
  data: ExchangeRates;
};

export default function ExchangeTableitem({ data }: Props) {
  const { reserve, exchanger_name, avg_rating, exchanger_id } = data;
  const { state } = useContext(GlobalContext);
  const { currency_pairs } = state;
  const [firstPair, secondPair] = currency_pairs;

  const isHighlighted = isExchangerHighlight(data);

  const handleRedirectShop = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (!exchanger_id) event.preventDefault();

    event.stopPropagation();

    createExchengerClick({ exchanger_id });

    if (currency_pairs.some(({ id }) => !id)) return;

    const data = {
      currency_from: firstPair.id,
      currency_to: secondPair.id,
      exchanger_id,
    };
    createPairClick(data);
  };

  return (
    <div className={styles.body_item}>
      <div
        className={classNames(
          styles.wrapper,
          isHighlighted && styles.highlighted
        )}
        id={data.exchanger_id}
        role="button"
        aria-hidden="true"
      >
        <ExchangerItemTitle
          onClick={handleRedirectShop}
          name={exchanger_name}
          exchanger={data}
        />
        <ExchangerFeatures onClick={handleRedirectShop} exchanger={data} />
        <ExchangeCourse onClick={handleRedirectShop} exchanger={data} />
        <ExchangeReserve
          reserve={`${reserve}`}
          exchanger={data}
          onClick={handleRedirectShop}
        />
        <ExchangerReviews exchanger={data} />
        <ExchangeRating rating={+avg_rating} exchanger={exchanger_name} />
      </div>
    </div>
  );
}

export const MemoizedExchangeTableItem = React.memo(ExchangeTableitem);
