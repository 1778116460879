import Image from "next/image";
import { IndicatorsContainerProps, components } from "react-select";
import { OptionsDataProps } from "@common/type/type";
import ArrowDrop from "@public/svg/icons/arrow_drop_down.svg";
import spinnerSrc from "@public/svg/loader/Spinner.gif";
import styles from "./CustomIndicatorsContainer.module.scss";

type ExtraProps = {
  isLoading?: boolean;
};
type Props = {
  extra: ExtraProps;
  props: IndicatorsContainerProps<OptionsDataProps>;
};

const { IndicatorsContainer } = components;

export default function CustomIndicatorsContainer({ extra, props }: Props) {
  const { loader_container } = styles;

  const { isLoading } = extra;

  return (
    <IndicatorsContainer {...props}>
      {isLoading ? (
        <div className={loader_container}>
          <Image src={spinnerSrc} alt="" />
        </div>
      ) : (
        <ArrowDrop />
      )}
    </IndicatorsContainer>
  );
}
