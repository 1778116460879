import RatingStars from "@components/common/RatingStars";
import Typography from "@components/common/Typography";
import { formatNumber } from "@utils/formatNum";
import { useHandleExchangerClick } from "app/hooks/useHandleExchangerClick";
import styles from "./ExchangeRating.module.scss";

type Props = {
  rating: number;
  exchanger?: string;
};

export default function ExchangeRating({ rating = 0, exchanger }: Props) {
  const handleClick = useHandleExchangerClick();

  return (
    <div
      role="button"
      aria-hidden
      onClick={event => exchanger && handleClick(event, exchanger)}
      className={styles.container}
    >
      <RatingStars rating={rating} />
      <Typography addClass={styles.rating} as="p">
        {formatNumber(rating, 1)}
      </Typography>
    </div>
  );
}
