export const getRatingInStar = (value: number) => {
  let ratingStars = [];
  const totalStars = 5;
  const fullStars = Math.floor(value);
  const hasHalfStar = value % 1 >= 0.5;
  const emptyStars = totalStars - fullStars - (hasHalfStar ? 1 : 0);
  // console.log(`emptyStars: ${emptyStars} value: ${value}`);

  ratingStars = [
    ...Array(fullStars).fill("full"),
    ...Array(hasHalfStar).fill(hasHalfStar),
    ...Array(emptyStars).fill("empty"),
  ];

  return ratingStars;
};
