import { PropsWithChildren, useState } from "react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import Image from "next/image";
import Typography from "@components/common/Typography";
import { getYearPostfix } from "@utils/getYearPostfix";
import placeholderSrc from "@public/svg/icons/question_mark.svg";
import { AppLanguage } from "@common/enum/enum";
import { ExchangeRates } from "@common/type/ExchangerData.type";
import styles from "./TooltipCard.module.scss";

type ExtraProps = { data: ExchangeRates };
type Props = PropsWithChildren & ExtraProps;

export default function TooltipCard({ data, children }: Props) {
  const {
    name,
    country_flag_url,
    country,
    en_country,
    ru_country,
    ua_country,
    years_working,
  } = data;
  const defualtCountryUrl = country_flag_url || `/svg/flags/${country}.svg`;
  const langMap = {
    [AppLanguage.EN]: en_country,
    [AppLanguage.RU]: ru_country,
    [AppLanguage.UA]: ua_country,
  };

  const { locale, defaultLocale } = useRouter();
  const lang = (locale || defaultLocale) as AppLanguage;
  const { t } = useTranslation(["tooltip", "common", "changerInfo"]);
  const [logoUrl, setLogoUrl] = useState(defualtCountryUrl);

  const yearsPostfixKey = getYearPostfix(years_working);
  const yearsText = t(`changerInfo:${yearsPostfixKey}`);
  const countryText = langMap[lang] || country || t("common:noInfo");
  const handleLogoError = () => setLogoUrl(placeholderSrc);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title_wrapper}>
        <Typography as="h5" addClass={styles.title}>
          {name}
        </Typography>
      </div>
      <div className={styles.info}>
        <div className={styles.country}>
          <Image
            width={12}
            height={12}
            src={logoUrl}
            onError={handleLogoError}
            alt={country}
            style={{ borderRadius: "50%" }}
          />
          <Typography as="p" addClass={styles.country__title}>
            {countryText}
          </Typography>
        </div>
        <div className={styles.divisor} />
        <div className={styles.info_description}>
          <Typography as="p" addClass={styles.info_description__head}>
            {t("experience")}
          </Typography>
          <Typography as="p" addClass={styles.info_description__p}>
            {years_working} {yearsText}
          </Typography>
        </div>
      </div>
      {children}
    </div>
  );
}
