import ReverseIcon from "@components/common/Icon/ReverseIcon";
import styles from "./SwapButton.module.scss";

type Props = {
  onClick: () => void;
};

export default function SwapButton({ onClick }: Props) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={styles.swap_button}
      aria-label="swap pair"
    >
      <ReverseIcon width={28} fill="black" />
    </button>
  );
}
