import React from "react";
import { useRouter } from "next/router";
import { AppRoute } from "@common/enum/enum";

export const useHandleExchangerClick = () => {
  const router = useRouter();

  return (e: React.MouseEvent, name?: string) => {
    e.preventDefault();

    const target = e.target as HTMLElement;

    if (target.tagName !== "circle" && target.tagName !== "path") {
      router.push(`/${AppRoute.EXCHANGERS}/${name?.toLowerCase()}`);
    }
  };
};
