import { CurrencyIn, ExchangeRatesResponse } from "@common/type/type";
import { APIService, instanceAxiosGoApi } from "app/service/api-service";

export const getCurrencyRates = async (
  body: CurrencyIn,
  filter: string,
  order: boolean,
  limit = 10,
  offset = 0
) => {
  const urlSearchParams = new URLSearchParams({
    filter_by: filter,
    order: String(order),
    limit: String(limit),
    offset: String(offset),
  });

  const res = await instanceAxiosGoApi.get(
    `/exchange-rates?from_currency=${body.currency_from}&to_currency=${body.currency_to}&${urlSearchParams}`
  );
  return res.data;
};
