type Props = {
  fill?: string;
  width?: number;
  height?: number;
};

export default function ReverseIcon({
  fill = "white",
  width = 28,
  height = width,
}: Props) {
  return (
    <svg
      width={28}
      height={28}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="cached" clipPath="url(#clip0_4235_16668)">
        <path
          id="Vector"
          d="M18.6667 22.1667L14 17.5L14 21C10.1384 21 7.00004 17.8617 7.00004 14C7.00004 12.8217 7.29171 11.7017 7.81671 10.7333L6.11337 9.02999C5.20337 10.465 4.66671 12.1683 4.66671 14C4.66671 19.1567 8.84337 23.3333 14 23.3333L14 26.8333L18.6667 22.1667ZM14 6.99999C17.8617 6.99999 21 10.1383 21 14C21 15.1783 20.7084 16.2983 20.1834 17.2667L21.8867 18.97C22.7967 17.535 23.3334 15.8317 23.3334 14C23.3334 8.84332 19.1567 4.66666 14 4.66666L14 1.16666L9.33337 5.83332L14 10.5L14 6.99999Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_4235_16668">
          <rect
            width={width}
            height={width}
            fill={fill}
            transform="translate(28) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
