import {
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import ExpandButton from "@components/common/Button/ExpandButton";
import { AppRoute, CurrencyCategory, LoadingMode } from "@common/enum/enum";
import { GlobalContext } from "@context/GlobalContext";
import { useCurrencyRatesInfinite } from "app/hooks/use-currency-rates";
import {
  CryptoType,
  ExchangeRates,
  ExchangeRatesResponse,
  GlobalContextKind,
} from "@common/type/type";
import Typography from "@components/common/Typography";
import { TextButton } from "@components/common/Button";
import SortingMobile from "@components/common/SortingMobile";
import { LocationIcon } from "@components/common/Icon";
import LocationSearchForm from "@components/common/LocationSearchForm";
import {
  DEFAULT_CURRENCY_TABLE_LIMIT,
  DEFAULT_RATE_RESPONSE,
} from "@common/constant/constant";
import CoverLoader from "../Loader/CoverLoader";
import InitialLoader from "../Loader/InitialLoader";
import ExchangeTableHead from "./ExchangeTableHead";
import ExchangeTableitem from "./ExchangeTableItem";
import ExchangeNoFound from "./ExchangeNoFound";
import styles from "./ExchangeTable.module.scss";
import React from "react";
import { off } from "process";
import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { create } from "domain";
import { instanceAxiosGoApi } from "app/service/api-service";

type Props = {
  redirectMode?: true;
  pair?: CryptoType[];
};

const byRates = "sort-by-rates";
const byRating = "sort-by-rating";
const byAmount = "sort-by-amount";

type BodyProps = {
  showUpdatingLoader: boolean;
  data: InfiniteData<ExchangeRatesResponse>;
  isSuccess: boolean;
  id: string;
};

function TableBody({ showUpdatingLoader, data, id, isSuccess }: BodyProps) {
  return (
    <>
      {isSuccess && (
        <div className={styles.table}>
          {data.pages.map(page => (
            <React.Fragment key={page.offset}>
              {page?.items.map(item => (
                <ExchangeTableitem key={item.exchanger_id} data={item} />
              ))}
            </React.Fragment>
          ))}
          {showUpdatingLoader && data && <CoverLoader key={`${id}-loader`} />}
        </div>
      )}
    </>
  );
}

export function convertCurrency(
  exchangeRate: Pick<ExchangeRates, "fromAmount" | "toAmount">,
  inputAmount: number,
  isFromAmount: boolean
): { fromValue: number; toValue: number } {
  const rate = exchangeRate.toAmount / exchangeRate.fromAmount;
  let fromValue: number;
  let toValue: number;

  if (isFromAmount) {
    fromValue = inputAmount;
    toValue = inputAmount * rate;
  } else {
    toValue = inputAmount;
    fromValue = inputAmount / rate;
  }

  return { fromValue, toValue };
}

const MemoizedTableBody = React.memo(TableBody);

export default function ExchangeTable({ redirectMode }: Props) {
  const id = useId();
  const router = useRouter();
  const { state, dispatch } = useContext(GlobalContext);
  const { currencyRatesTable, currency_pairs, amount, currentCity } = state;
  const { order, filter } = currencyRatesTable;
  const [isOpen, setIsOpen] = useState(false);
  const titleRef = useRef<HTMLDivElement>(null);
  const [showUpdatingLoader, setShowUpdatingLoader] = useState(false);
  const { t } = useTranslation(["home"]);
  const [disabledClick, setDisabledClick] = useState(false);

  const queryKey = useMemo(
    () => [
      "currency-rate",
      {
        order,
        filter,
        currency_pairs: currency_pairs.map(({ abbreviation }) => abbreviation),
        currentCity,
      },
    ],
    [order, filter, currency_pairs, currentCity]
  );
  const queryFn = useCallback(
    async ({ pageParam = 0 }) => {
      const [fromCurrency, toCurrency] = currency_pairs.map(
        ({ abbreviation }) => abbreviation
      );
      if (currency_pairs.some(({ id }) => !id)) return DEFAULT_RATE_RESPONSE;
      let defaultRequest = `/exchange-rates?from_currency=${fromCurrency}&to_currency=${toCurrency}&filter_by=${filter}&order=${order}&limit=15&offset=${pageParam}`;
      if (currentCity?.short_name) {
        defaultRequest += `&city=${currentCity.short_name}`;
      }
      const res = await instanceAxiosGoApi.get<ExchangeRatesResponse>(
        defaultRequest
      );
      return res.data;
    },
    [currency_pairs, filter, order, currentCity]
  );

  const {
    data,
    isSuccess,
    isFetching,
    hasNextPage,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(queryKey, queryFn, {
    getNextPageParam: (lastPage: ExchangeRatesResponse, allPages) => {
      const totalFetchedItems = allPages.reduce(
        (total, page) => total + page.items.length,
        0
      );
      if (totalFetchedItems < lastPage.total) {
        return totalFetchedItems;
      }
      return undefined;
    },
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    retry: 0,

    refetchInterval: 15000,
  });

  const recalculatedData = useMemo(() => {
    if (!data) return null;

    const recalculatedPages = data.pages.map(page => {
      const recalculatedItems = page.items.map(item => {
        const rates = {
          fromAmount: item.fromAmount,
          toAmount: item.toAmount,
        };

        const { toValue } = convertCurrency(rates, amount, true);

        return {
          ...item,
          fromAmount: amount,
          toAmount: toValue,
        };
      });

      return {
        ...page,
        items: recalculatedItems,
      };
    });

    return {
      ...data,
      pages: recalculatedPages,
    };
  }, [data, amount]);

  const hasCash = currency_pairs.some(
    ({ category }) => category === CurrencyCategory.CASH
  );

  useEffect(() => {
    if (isSuccess && !isFetchingNextPage && showUpdatingLoader) {
      setShowUpdatingLoader(false);
    }
    if (!showUpdatingLoader && isFetchingNextPage) {
      setShowUpdatingLoader(true);
    }
  }, [isFetchingNextPage, showUpdatingLoader, isSuccess]);

  const redirect = () => {
    const pathFrom = currency_pairs[0].abbreviation.toLowerCase();
    const pathTo = currency_pairs[1].abbreviation.toLowerCase();
    router.push(`/${AppRoute.RATES}/${pathFrom}-to-${pathTo}`);
  };

  const handleExpand = () => {
    if (redirectMode) {
      redirect();
      return;
    }

    if (hasNextPage && !isFetching && !isFetchingNextPage) {
      fetchNextPage();
    }
  };
  const isExpandable = useMemo(() => hasNextPage, [hasNextPage]);

  if (isLoading) {
    return <InitialLoader />;
  }

  if (!recalculatedData?.pages.length) {
    return <ExchangeNoFound />;
  }

  const handleChooseCityClick = () => {
    if (redirectMode) {
      setDisabledClick(true);
      redirect();
    } else {
      setIsOpen(true);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.heading} ref={titleRef}>
        <Typography as="h3" addClass={styles.title}>
          <Typography as="span" addClass={styles.nonmobile}>
            🔥
          </Typography>
          {t("table-title")}
        </Typography>
        {hasCash && (
          <TextButton
            title={currentCity?.full_name ?? String(t("choose-location"))}
            icon={<LocationIcon />}
            onClick={handleChooseCityClick}
          />
        )}
        <div
          aria-hidden
          onClick={() => {
            if (redirectMode) {
              setDisabledClick(true);
              redirect();
            }
          }}
        >
          <SortingMobile
            disabledClick={disabledClick}
            disabled={redirectMode}
            options={[
              { key: "avg_rating", title: byRating },
              { key: "toAmount", title: byRates },
              { key: "byAmount", title: byAmount },
            ]}
            order={order}
            filter={filter}
            onOrder={() => {
              dispatch({
                type: GlobalContextKind.CURRENCY_RATES_TABLE,
                payload: { order: !order, loadingMode: LoadingMode.COVER },
              });
            }}
            onSort={filter => {
              dispatch({
                type: GlobalContextKind.CURRENCY_RATES_TABLE,
                payload: { filter, loadingMode: LoadingMode.COVER },
              });
            }}
          />
        </div>
      </div>
      <div className={styles.table_wrapper}>
        <ExchangeTableHead onClick={() => setShowUpdatingLoader(true)} />
        <MemoizedTableBody
          showUpdatingLoader={showUpdatingLoader}
          data={recalculatedData as InfiniteData<ExchangeRatesResponse>}
          id={id}
          isSuccess={isSuccess}
        />
      </div>
      {isExpandable && (
        <ExpandButton
          isFetching={isFetchingNextPage}
          handleClick={handleExpand}
        />
      )}
      {isOpen && <LocationSearchForm onClose={() => setIsOpen(false)} />}
    </div>
  );
}
