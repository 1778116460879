import Typography from "@components/common/Typography";
import LikeIcon from "@public/svg/icons/like.svg";
import Dislike from "@public/svg/icons/dislike.svg";
import { useHandleExchangerClick } from "app/hooks/useHandleExchangerClick";
import { ExchangeRates } from "@common/type/ExchangerData.type";
import styles from "./ExchangeReviews.module.scss";

type Props = {
  exchanger?: ExchangeRates;
};

export default function ExchangerReviews({ exchanger }: Props) {
  const handleClick = useHandleExchangerClick();

  return (
    <div
      role="button"
      aria-hidden
      onClick={event => handleClick(event, exchanger?.exchanger_name)}
      className={styles.container}
    >
      {exchanger && (
        <>
          <div className={styles.side_container}>
            <LikeIcon />
            <Typography as="span" addClass={styles.count}>
              {exchanger.like}
            </Typography>
          </div>
          <div className={styles.divisor} />
          <div className={styles.side_container}>
            <Dislike />
            <Typography as="span" addClass={styles.count}>
              {exchanger.dislike}
            </Typography>
          </div>
        </>
      )}
    </div>
  );
}
