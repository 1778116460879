import { ButtonHTMLAttributes } from "react";
import classNames from "classnames";
import SortIcon from "@public/svg/icons/sort.svg";
import styles from "./SortButton.module.scss";

type ExtraProps = { sort?: boolean; isActive?: boolean };
type Props = ButtonHTMLAttributes<HTMLButtonElement> & ExtraProps;

export default function SortButton({ sort = false, isActive }: Props) {
  const { wrapper, up, down, active } = styles;

  const classList = classNames(isActive && active, sort ? down : up);

  return (
    <button type="button" className={wrapper}>
      <SortIcon className={classList} />
    </button>
  );
}
