import { useContext } from "react";
import Typography from "@components/common/Typography";
import { ExchangeRates, Nullable } from "@common/type/type";
import ExchangerIcon from "@components/common/Icon/ExchangerIcon";
import TooltipItem from "@components/common/TooltipItem";
import TooltipCard from "@components/common/TooltipItem/TooltipCard";
import { TooltipPlacement } from "@common/enum/enum";
import QuestionIcon from "@public/svg/icons/question_mark.svg";
import { GlobalContext } from "@context/GlobalContext";
import styles from "./ExchangeItemTitle.module.scss";

type Props = {
  exchanger: Nullable<ExchangeRates>;
  name: string;
  onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

export default function ExchangerItemTitle({
  exchanger,
  name,
  onClick,
}: Props) {
  const { state } = useContext(GlobalContext);
  const { currency_pairs } = state;

  const tooltipIcon = exchanger?.logo_url ? (
    <ExchangerIcon src={exchanger.logo_url} alt={name} />
  ) : (
    <div className={styles.placeholder_container}>
      <QuestionIcon />
    </div>
  );

  const params = new URLSearchParams({
    from: currency_pairs[0].abbreviation.toUpperCase(),
    to: currency_pairs[1].abbreviation.toUpperCase(),
  });

  const redirectLink = `${exchanger?.website_url || ""}?${params}`;
  return (
    <a
      target="_blank"
      href={exchanger?.website_url ? redirectLink : "#"}
      rel="noreferrer"
      style={{ gridArea: "title" }}
      className={styles.title}
      onClick={onClick}
    >
      <div className={styles.icon_container}>
        {exchanger ? (
          <TooltipItem
            placement={TooltipPlacement.BOTTOM_START}
            content={<TooltipCard data={exchanger} />}
          >
            {tooltipIcon}
          </TooltipItem>
        ) : (
          tooltipIcon
        )}
      </div>
      <Typography addClass={styles.name} as="p">
        {name}
      </Typography>
    </a>
  );
}
