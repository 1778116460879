import { useContext } from "react";
import { useTranslation } from "next-i18next";
import classNames from "classnames";
import { GlobalContext } from "@context/GlobalContext";
import { GlobalContextKind } from "@common/type/type";
import { LoadingMode } from "@common/enum/enum";
import ExchangeSortButton from "../ExchangeSortButton";
import styles from "./ExchangeTableHead.module.scss";

const sortBtnList = [
  { filter: "exchanger_name", title: "name" },
  null,
  { filter: "toAmount", title: "course" },
  { filter: "reserve", title: "reserve" },
  { filter: "avg_rating", title: "rating" },
  { filter: "num_reviews", title: "reviews" },
];

type Props = {
  onClick?: () => void;
};

export default function ExchangeTableHead({ onClick }: Props) {
  const { table_head } = styles;

  const { state, dispatch } = useContext(GlobalContext);
  const { currencyRatesTable } = state;
  const { order } = currencyRatesTable;

  const { t } = useTranslation(["monitoring"]);

  const handleSort = (filter: string) => {
    onClick?.();
    dispatch({
      type: GlobalContextKind.CURRENCY_RATES_TABLE,
      payload: {
        order: !order,
        filter,
        loadingMode: LoadingMode.COVER,
      },
    });
  };

  return (
    <div data-table-anchor="table-anchor" className={classNames(table_head)}>
      {sortBtnList.map(data => {
        if (!data) return <div key="empty" />;

        const { filter, title } = data;
        const sort = currencyRatesTable.filter === filter ? order : true;

        return (
          <ExchangeSortButton
            key={filter}
            handleSort={() => console.log("")}
            title={t(title)}
            isAsc={sort}
            arrowIsVisible={false}
          />
        );
      })}
    </div>
  );
}
