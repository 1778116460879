import { useContext, useEffect, useRef } from "react";
import classNames from "classnames";
import Typography from "@components/common/Typography";
import { ExchangeRates } from "@common/type/ExchangerData.type";
import { GlobalContext } from "@context/GlobalContext";
import { isExchangerHighlight } from "@utils/isExchangerHighlight";
import { formatNumber } from "@utils/formatNum";
import styles from "./ExchangeCourse.module.scss";

type Props = {
  exchanger: ExchangeRates;
  onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

export default function ExchangeCourse({ onClick, exchanger }: Props) {
  const { maxamount, minamount, fromAmount, toAmount } = exchanger;
  const { state } = useContext(GlobalContext);
  const minRef = useRef<HTMLSpanElement>(null);
  const maxRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (minRef.current && maxRef.current) {
        const minRefHeight = minRef.current.offsetHeight;
        const maxRefHeight = maxRef.current.offsetHeight;

        minRef.current.classList.toggle(styles.reduced, minRefHeight > 20);
        maxRef.current.classList.toggle(styles.reduced, maxRefHeight > 20);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { currency_pairs } = state;
  const [firstPair, secondPair] = currency_pairs;

  const topFrom = formatNumber(fromAmount, 3, { minValue: 0.001 });
  const topTo = formatNumber(toAmount, 3, { minValue: 0.001 });
  const bottomMin = formatNumber(minamount, 3, { minValue: 0.001 }) || 0;
  const bottomMax = formatNumber(maxamount, 3, { minValue: 0.001 }) || 0;

  const isHighlighted = isExchangerHighlight(exchanger);
  const accentClassname = classNames(
    styles.bold,
    isHighlighted && styles.active
  );

  const firstAbbreviation = firstPair.abbreviation.toUpperCase();
  const secondAbbreviation = secondPair.abbreviation.toUpperCase();

  return (
    <a
      target="_blank"
      href={exchanger?.website_url || "#"}
      rel="noreferrer"
      className={styles.course}
      onClick={onClick}
    >
      <Typography as="p" addClass={styles.course_title}>
        <span className={accentClassname}>{topFrom}</span>
        <span className={styles.grey}>{firstAbbreviation}</span>
        <span className={accentClassname}>&nbsp;&nbsp;=&nbsp;&nbsp;</span>
        <span className={accentClassname}>{topTo}</span>
        <span className={styles.grey}>{secondAbbreviation}</span>
      </Typography>
      <Typography as="p" addClass={classNames(styles.course_minmax)}>
        <span
          ref={minRef}
          className={classNames(styles.minmax_content, styles.grey)}
        >
          <span style={{ whiteSpace: "nowrap" }}>{`min = ${bottomMin} `}</span>
          <span>{firstAbbreviation}</span>
        </span>
        <span className={classNames(styles.divisor, styles.grey)}>|</span>
        <span
          ref={maxRef}
          className={classNames(styles.minmax_content, styles.grey)}
        >
          <span style={{ whiteSpace: "nowrap" }}>{`max = ${bottomMax} `}</span>
          <span>{firstAbbreviation}</span>
        </span>
      </Typography>
    </a>
  );
}
